import { QATypeLabel } from './../models/QATypeLabel.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  Division,
  FillerMaterial,
  JointType,
  Material,
  RTAcceptedRejected,
  RTPercentage,
  StampAllocation,
  Unit,
  WeldGroup,
  WeldProcess,
  WPS,
  Size,
  Schedule,
  MaterialForm,
  QAType,
  Role,
  UserRole,
  Permission,
  ContractCompany,
  WeldTypeIdentifier,
  DefectType
} from '../models';

@Injectable({
  providedIn: 'root'
})
export class DropdownApiService {
  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient) {}
  //#endregion

  //#region 'Get'
  public GetContractCompanyByRefineryAsync(refineryid: number): Observable<ContractCompany[]> {
    return this._http.get<ContractCompany[]>(
      `${environment.apiEndpoint}/ContractCompany/GetContractCompanyByRefineryAsync`,
      {
        params: { refineryid }
      }
    );
  }

  public GetDivisionsByRefineryAsync(refineryid: number): Observable<Division[]> {
    return this._http.get<Division[]>(`${environment.apiEndpoint}/division/GetDivisionsByRefineryAsync`, {
      params: { refineryid }
    });
  }

  public GetWeldTypeIdentifierAsync(): Observable<WeldTypeIdentifier[]> {
    return this._http.get<WeldTypeIdentifier[]>(
      `${environment.apiEndpoint}/WeldTypeIdentifier/GetWeldTypeIdentifierAsync`
    );
  }

  public GetFillerMaterialsAsync(): Observable<FillerMaterial[]> {
    return this._http.get<FillerMaterial[]>(`${environment.apiEndpoint}/fillermaterial/GetFillerMaterialsAsync`);
  }

  public GetJointTypesAsync(): Observable<JointType[]> {
    return this._http.get<JointType[]>(`${environment.apiEndpoint}/jointtype/GetJointTypesAsync`);
  }

  public GetMaterialAsync(): Observable<Material[]> {
    return this._http.get<Material[]>(`${environment.apiEndpoint}/Material/GetMaterialAsync`);
  }

  public GetMaterialFormAsync(): Observable<MaterialForm[]> {
    return this._http.get<MaterialForm[]>(`${environment.apiEndpoint}/MaterialForm/GetMaterialFormAsync`);
  }

  public GetQATypeAsync(): Observable<QAType[]> {
    return this._http.get<QAType[]>(`${environment.apiEndpoint}/QAType/GetQATypeAsync`);
  }

  public GetQATypeLabelAsync(qaTypeID: number): Observable<QATypeLabel[]> {
    return this._http.get<QATypeLabel[]>(`${environment.apiEndpoint}/QATypeLabel/GetQATypeLabelAsync`, {
      params: { qaTypeID }
    });
  }

  public GetRolesAsync(): Observable<Role[]> {
    return this._http.get<Role[]>(`${environment.apiEndpoint}/Role/GetRolesAsync`);
  }

  public GetAvailableUserRolesByUserAsync(userId: string, refineryId: number): Observable<UserRole[]> {
    return this._http.get<UserRole[]>(`${environment.apiEndpoint}/WeldManagerUser/GetAvailableUserRolesByUserAsync`, {
      params: { userId, refineryId }
    });
  }

  public GetRTAcceptedRejectedsByRefineryAsync(refineryid: number): Observable<RTAcceptedRejected> {
    return this._http.get<RTAcceptedRejected>(
      `${environment.apiEndpoint}/rtacceptedrejected/GetRTAcceptedRejectedsByRefineryAsync`,
      {
        params: { refineryid }
      }
    );
  }

  public GetRTPercentageByRefineryAsync(refineryid: number): Observable<RTPercentage[]> {
    return this._http.get<RTPercentage[]>(`${environment.apiEndpoint}/RTPercentage/GetRTPercentageByRefineryAsync`, {
      params: { refineryid }
    });
  }

  public GetScheduleAsync(): Observable<Schedule[]> {
    return this._http.get<Schedule[]>(`${environment.apiEndpoint}/Schedule/GetScheduleAsync`);
  }

  public GetSizeAsync(): Observable<Size[]> {
    return this._http.get<Size[]>(`${environment.apiEndpoint}/Size/GetSizeAsync`);
  }

  public GetUnitsByRefineryAndDivisionAsync(refineryid: number, divisionid: number): Observable<Unit[]> {
    return this._http.get<Unit[]>(`${environment.apiEndpoint}/unit/GetUnitsByRefineryAsync`, {
      params: { refineryid, divisionid }
    });
  }

  public GetUnitsByRefineryAsync(refineryid: number): Observable<Unit[]> {
    return this._http.get<Unit[]>(`${environment.apiEndpoint}/unit/GetUnitsByRefineryAsync`, {
      params: { refineryid }
    });
  }

  public GetWeldersStampAllocationAsync(refineryid: number): Observable<StampAllocation> {
    return this._http.get<StampAllocation>(`${environment.apiEndpoint}/welder/GetWeldersStampAllocationAsync`, {
      params: { refineryid }
    });
  }

  public GetWelderStampsByRefineryAsync(refineryid: number): Observable<StampAllocation> {
    return this._http.get<StampAllocation>(`${environment.apiEndpoint}/welder/GetWelderStampsByRefineryAsync`, {
      params: { refineryid }
    });
  }

  public GetWeldGroupByRefineryAsync(refineryid: number): Observable<WeldGroup[]> {
    return this._http.get<WeldGroup[]>(`${environment.apiEndpoint}/weldgroup/GetWeldGroupByRefineryAsync`, {
      params: { refineryid }
    });
  }

  public GetWeldProcessesAsync(): Observable<WeldProcess[]> {
    return this._http.get<WeldProcess[]>(`${environment.apiEndpoint}/weldprocess/GetWeldProcessesAsync`);
  }

  public GetWPsByRefineryAsync(refineryid: number): Observable<WPS[]> {
    return this._http.get<WPS[]>(`${environment.apiEndpoint}/wps/GetWPsByRefineryAsync`, {
      params: { refineryid }
    });
  }

  public GetDefectTypeAsync(defectTypeId?: number): Observable<DefectType[]> {
    return this._http.get<DefectType[]>(`${environment.apiEndpoint}/DefectType/GetDefectTypeAsync`);
  }

  public GetPermissionsAsync(): Observable<Permission[]> {
    return this._http.get<Permission[]>(`${environment.apiEndpoint}/Permission/GetPermissionsAsync`);
  }

  //#endregion
}
