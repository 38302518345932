<div>
  <div class="row">
    <div *ngIf="ndeLinkFromQMDB.length == 0">
      You're about to process <span style="font-weight: bold">{{ selectedLogs.length }} {{ selectedLogs.length <= 1 ? 'log' : 'logs' }}</span>
    </div>
  </div>

  <div *ngIf="ndeLinkFromQMDB.length != 0">
    <div style="font-weight: bold">NDE submitted successfully!</div>
    <div>Visit <a href="{{ ndeLinkFromQMDB }}" target="_blank" rel="noopener">QMDB</a> to view the NDEs.</div>
  </div>

  <div class="row">
    <div class="col-12 buttons-padding" style="display: table">
      <p-button (onClick)="cancel()" class="red-btn mt-4 action-btn"><em class="pi pi-times-circle mr-2"></em> {{ ndeLinkFromQMDB.length == 0 ? 'Cancel' : 'Close' }}</p-button>
      <p-button class="mt-4 mr-2 action-btn" (onClick)="submitNewLog()">Submit NDEs</p-button>
    </div>
  </div>
</div>
