import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observer } from 'rxjs';
import { AccountInfo } from '@azure/msal-browser';
import { CalAngularService } from '@cvx/cal-angular';
import { RoleApiService, WeldManagerUserApiService } from '../api';

@Injectable()
export class AuthService {
  //#region 'Variables'
  private userInfo: AccountInfo;
  static calConfig = {
    autoSignIn: true,
    popupForLogin: false,
    cacheLocation: 'localStorage',
    instance: 'https://login.microsoftonline.com/',
    tenantId: 'fd799da1-bfc1-4234-a91c-72b3a1cb9e26',
    clientId: AuthService.getClientId(),
    redirectUri: AuthService.getRedirectUri(),
    oidcScopes: ['openid', 'profile', 'User.Read', 'offline_access'],
    graphScopes: ['User.Read']
  };
  //#endregion

  //#region 'Angular Life Cycle'
  constructor(
    private readonly _srvCal: CalAngularService,
    private readonly router: Router,
    private readonly _srvWeldUser: WeldManagerUserApiService,
    private readonly _srvRole: RoleApiService,
    private readonly authService: CalAngularService
  ) {}
  //#endregion

  //#region 'Login'
  public login() {
    this._srvCal
      .isUserSignedIn()
      .toPromise()
      .then((isLoggedIn: boolean) => {
        if (isLoggedIn) {
          this.getUserInfo();
        } else {
          setTimeout(() => {
            this.login();
          }, 1000);
        }
      });
  }

  public getCurrentUser() {
    return this.authService.getAccount();
  }

  public getUserInfo() {
    this.userInfo = this._srvCal.getAccount();
    if (this.userInfo == null) {
      console.error('User info not found not found');
    } else {
      this.loadRefineryId(this.userInfo.localAccountId);
    }
  }
  //#endregion

  //#region 'Gets'
  private static getClientId(): string {
    const hostname = window?.location?.hostname;
    const testClientId = '3e19cf48-cced-4e7b-beee-6ecd98de9472';
    const prodClientId = '37573605-58b5-4ae5-a95a-925d78c165e6';

    if (
      hostname.includes('localhost') ||
      hostname.includes('wldmgr-dev.azure.chevron.com') ||
      hostname.includes('wldmgr-test.azure.chevron.com')
    ) {
      return testClientId;
    } else {
      return prodClientId;
    }
  }

  private static getRedirectUri(): string {
    const hostname = window?.location?.hostname;

    if (hostname.includes('localhost')) {
      return 'http://localhost:4200';
    } else if (hostname.includes('wldmgr-dev.azure.chevron.com')) {
      return 'https://wldmgr-dev.azure.chevron.com';
    } else if (hostname.includes('wldmgr-test.azure.chevron.com')) {
      return 'https://wldmgr-test.azure.chevron.com';
    } else {
      return 'https://wldmgr.azure.chevron.com';
    }
  }
  //#endregion

  //#region 'General Methods'
  public getScopes(): string[] {
    return [`${AuthService.getClientId()}/user_impersonation`];
  }

  private loadRefineryId(userId: string): void {
    if (userId) {
      this._srvWeldUser.GetUserRolesByUserAsync(userId).subscribe({
        next: (res: any) => {
          if (res.length > 0) {
            const user = {
              refineryId: res[0].refineryId,
              refineryName: res[0].refineryName,
              roleId: res[0].roleId,
              roleName: res[0].roleName,
              userId: res[0].userId,
              userName: `${res[0].lastName}, ${res[0].firstName}`,
              permissions: []
            };

            this._srvRole.GetRolesPermissionsByRefineryAsync(Number(user.refineryId)).subscribe({
              next: (role: any) => {
                const PERMISSIONS = role.filter(obj => obj.roleId === user.roleId);
                user.permissions = PERMISSIONS.map(x => x.permissionName.toLowerCase());
                localStorage.removeItem('user');
                localStorage.setItem('user', JSON.stringify(user));
                this.router.navigateByUrl('/');
              },
              error: (err: any) => {
                if (err.status === 401) {
                  this.router.navigateByUrl('/error/not-authorized');
                }
              }
            });
          } else {
            this.login();
          }
        },
        error: (err: any) => {
          if (err.status === 401) {
            this.router.navigateByUrl('/error/not-authorized');
          }
        }
      });
    }
  }

  //#endregion
}
