<div class="row pad">
  <div class="col-1"><img src="../assets/cvx-80x80.png" alt="Chevron" /></div>
  <div class="col-1"></div>

  <div class="col-8 divCenterFlex">
    <h1 class="divCenterFlex">
      Weld Manager Tool
      <span class="red" style="font-family: 'GothamBold', 'Helvetica Neue', Helvetica, Arial, sans-serif; margin-left: 0.5rem">{{ showEnvironment() }}</span>
    </h1>
  </div>

  <div class="col-2 center-vert" *ngIf="userName.length > 0">
    <span class="user"
      >{{ userName }}<br />
      Refinery: {{ refineryName }}<br /></span
    ><em class="pi pi-user user"></em>
  </div>
</div>

<div class="navbar">
  <div>
    <span class="btn-span" (click)="routePage('/log')" *ngIf="validatePermissions('view weld log')">Weld Log</span>
  </div>

  <div>
    <span class="btn-span" (click)="routePage('/certification')" *ngIf="validatePermissions('view welder certification')">Welder Certification</span>
  </div>

  <!-- #region Reporting -->
  <div class="col-2 d-flex justify-content-center">
    <span class="btn-span" (click)="reportingMenu.toggle($event)">Reporting</span>
    <p-tieredMenu #reportingMenu [model]="reportingMenuItems" [popup]="true" appendTo="body"></p-tieredMenu>
  </div>
  <!-- #endregion -->

  <div>
    <span class="btn-span" (click)="adminMenu.toggle($event)" *ngIf="validatePermissions('system admin')">Admin</span>
    <p-tieredMenu #adminMenu [model]="adminMenuItems" [popup]="true" appendTo="body"></p-tieredMenu>
  </div>

  <!-- Help is always far right, regardless of what options user has above -->
  <div style="grid-column: 5/6">
    <span class="btn-span" (click)="menu.toggle($event)">Help</span>
    <p-tieredMenu #menu [model]="menuItems" [popup]="true" appendTo="body"></p-tieredMenu>
  </div>
</div>
