import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WeldLog } from '../../../../core/models';
import {
  DropdownService,
  KeypressValidationService,
  LoadingIndicatorService,
  WeldLogService,
  WeldManagerUserService,
  WeldCertificationService
} from '../../../../data/services';
import { NotificationToast, NotificationService } from '../../../shared/notification';
import { WeldLogTable } from 'src/app/data/models';

@Component({
  selector: 'app-create-nde',
  templateUrl: './create-nde.component.html',
  styleUrls: ['./create-nde.component.scss']
})
export class CreateNdeComponent implements OnInit {
  //#region 'Variables'
  @Input() public userPermissions: any;
  @Input() public hideActionBtns: boolean;
  @Input() public selectedLogs: WeldLog[];
  @Output() public onCancel: EventEmitter<any> = new EventEmitter<any>();
  public qmdbRequest: any;
  public ndeLinkFromQMDB: string = '';

  //#region 'Angular Life Cycle'
  constructor(
    public _srvDropDown: DropdownService,
    private _srvNotify: NotificationService,
    private _loader: LoadingIndicatorService,
    public keyValidation: KeypressValidationService,
    public _srvWCS: WeldCertificationService,
    public _srvWeldUsers: WeldManagerUserService
  ) {}
  //#endregion

  // #region 'General Methods'
  ngOnInit(): void {
    this.clearAllFields();
    this.getNameAndEmail(this.selectedLogs);
  }

  ngOnDestroy(): void {
    this.clearAllFields();
  }

  private getNameAndEmail(selectedLogs) {
    this._srvWCS.loadRefineries().subscribe(dto => {
      let name = dto[0].lastName + ', ' + dto[0].firstName;
      let email = dto[0].emailId;

      this.constructBrandNewJSON(selectedLogs, name, email);
    });
  }

  public constructBrandNewJSON(selectedLogs: WeldLog[], name: string, email: string) {
    // 1) Create a mapping NDE <--> weld logs
    let ndeWeldLogMap = this.getNDEWeldLogMapping(selectedLogs);

    //2) Add EWO to each entry in ndeWeldLogMap
    let ndeWeldLogEwoMap = this.attachEWONumberToNDEWeldLogMap(ndeWeldLogMap);

    //3) Add drawing number to each entry in ndeWeldLogEwoMap
    let ndeWeldLogEwoDrawMap = this.attachDrawingNumberToNDEWeldLogEwoMap(ndeWeldLogEwoMap);

    //4) Group them
    let NDEAndEWOPairs = this.getNDEAndEWOPairs(ndeWeldLogEwoDrawMap);

    //5) Group NDE and EWO pairs with logs
    let groupedNDEsEWOAndLogs = this.groupNDEAndEWOPairsWithLogs(NDEAndEWOPairs, ndeWeldLogEwoDrawMap);

    //6) Group logs by drawing
    let groupedLogsByDrawing = this.groupLogsByDrawing(groupedNDEsEWOAndLogs);
    // console.log(groupedLogsByDrawing);

    //7) Replace each log for its relevant info
    let qmdbBody = this.extractAndPutRelevantInfo(groupedLogsByDrawing);

    let finalRequest = {
      requesterEmail: name,
      requesterName: email,
      ndeRequest: qmdbBody
    };

    this.qmdbRequest = finalRequest;
  }

  private getNDEWeldLogMapping(selectedLogs: WeldLog[]) {
    let ndeWeldLogMap = [];
    for (let i = 0; i < selectedLogs.length; i++) {
      let currentLog = selectedLogs[i];
      let qaListOfCurrentLog = currentLog.weldLogQAs;

      for (let j = 0; j < qaListOfCurrentLog.length; j++) {
        let currentQA = qaListOfCurrentLog[j].qaTypeName;
        let entryInMap = [currentQA, currentLog];
        ndeWeldLogMap.push(entryInMap);
      }
    }

    return ndeWeldLogMap;
  }

  private attachEWONumberToNDEWeldLogMap(ndeWeldLogMap: any[]) {
    // ndeWeldLogMap is an array of arrays
    // the second index in each array is the weld log

    let ndeWeldLogEwoMap = [];
    for (let i = 0; i < ndeWeldLogMap.length; i++) {
      let currentNDEType = ndeWeldLogMap[i][0];
      let currentLog = ndeWeldLogMap[i][1];
      let ewoNumber = currentLog.ewonumber;
      let entryInMap = [currentNDEType, currentLog, ewoNumber];
      ndeWeldLogEwoMap.push(entryInMap);
    }

    return ndeWeldLogEwoMap;
  }

  private attachDrawingNumberToNDEWeldLogEwoMap(ndeWeldLogEwoMap: any[]) {
    // ndeWeldLogEwoMap is an array of arrays
    // first index in each array is the NDE type, second index is the weld log
    // third index is the ewo number

    let ndeWeldLogEwoDrawMap = [];
    for (let i = 0; i < ndeWeldLogEwoMap.length; i++) {
      let currentNDEType = ndeWeldLogEwoMap[i][0];
      let currentLog = ndeWeldLogEwoMap[i][1];
      let ewoNumber = ndeWeldLogEwoMap[i][2];
      let drawingNumber = currentLog.drawing;
      let entryInMap = [currentNDEType, currentLog, ewoNumber, drawingNumber];
      ndeWeldLogEwoDrawMap.push(entryInMap);
    }

    return ndeWeldLogEwoDrawMap;
  }

  private getNDEAndEWOPairs(ndeWeldLogEwoDrawMap: any[]) {
    //1) Sort the ndeWeldLogEwoDrawMap alphabetically by NDE type
    ndeWeldLogEwoDrawMap.sort((a, b) => a[0].localeCompare(b[0]));

    //2) Create a combination of all the NDE and EWO
    let NDEAndEWOCombination = [];
    for (let i = 0; i < ndeWeldLogEwoDrawMap.length; i++) {
      let currentNDEType = ndeWeldLogEwoDrawMap[i][0];
      let ewoNumber = ndeWeldLogEwoDrawMap[i][2];
      let entryInCombination = [currentNDEType, ewoNumber];

      // if the combination is not already in the array, add it
      if (!NDEAndEWOCombination.some(arr => arr[0] === currentNDEType && arr[1] === ewoNumber)) {
        NDEAndEWOCombination.push(entryInCombination);
      }
    }

    return NDEAndEWOCombination;
  }

  private groupNDEAndEWOPairsWithLogs(NDEAndEWOPairs: any[], ndeWeldLogEwoDrawMap: any[]) {
    let logsGroupedByNDEAndEWO = [];
    for (let i = 0; i < NDEAndEWOPairs.length; i++) {
      let currentNDEType = NDEAndEWOPairs[i][0];
      let currentEWO = NDEAndEWOPairs[i][1];

      let logsWithSameNDEAndEWO = ndeWeldLogEwoDrawMap.filter(
        entry => entry[0] == currentNDEType && entry[2] == currentEWO
      );

      let groupedNDEsEWOLogs = {
        ndeType: currentNDEType,
        ewoNumber: currentEWO,
        drawings: logsWithSameNDEAndEWO
      };

      logsGroupedByNDEAndEWO.push(groupedNDEsEWOLogs);
    }

    return logsGroupedByNDEAndEWO;
  }

  private groupLogsByDrawing(inputArray: any[]): any[] {
    return inputArray.map(item => {
      const groupedLogs: { [key: string]: any[] } = {};
      if (Array.isArray(item.drawings)) {
        item.drawings.forEach((log: any[]) => {
          const drawing = log[3];
          const weldLog = log[1];
          if (groupedLogs[drawing]) {
            groupedLogs[drawing].push(weldLog);
          } else {
            groupedLogs[drawing] = [weldLog];
          }
        });
      }
      return { ...item, drawings: groupedLogs };
    });
  }

  private extractAndPutRelevantInfo(log: any[]) {
    let updatedLog = JSON.parse(JSON.stringify(log)); // Deep copy of the original log array

    for (let i = 0; i < updatedLog.length; i++) {
      let oneLog = updatedLog[i];
      let logArrayKey = oneLog.drawings;
      let relevantNDEType = oneLog.ndeType;
      const updatedDrawings: any[] = [];

      // Loop over the key-value pairs
      for (const [key, value] of Object.entries(logArrayKey)) {
        const updatedValue = this.extractInfoFromWldLogs(value, relevantNDEType);

        // Create drawing objects and add them to the updatedDrawings array
        for (const item of updatedValue) {
          updatedDrawings.push({
            drawing: key !== 'null' ? key : '',
            ...item
          });
        }
      }

      oneLog.drawings = updatedDrawings;
    }

    return updatedLog;
  }

  private extractInfoFromWldLogs(nestedLog: any, relevantNDEType: string) {
    let bodyArr = [];

    for (let i = 0; i < nestedLog.length; i++) {
      let eachLog = nestedLog[i];
      let obj = {};
      let matchingQATypes = eachLog.weldLogQAs.filter(entry => entry.qaTypeName === relevantNDEType);
      let matchingQATypesLabelNames = matchingQATypes[0].labelNames;
      let multipleQATypes = matchingQATypesLabelNames.includes(',');

      obj['weldNo'] = eachLog.weldNo != null ? eachLog.weldNo : '';
      obj['weldStage'] = '';
      obj['testStage'] = multipleQATypes ? matchingQATypesLabelNames.split(',')[i] : matchingQATypesLabelNames;
      obj['stencil'] = [...new Set(eachLog.weldLogStampProcesses.map(process => process.stampNumber))].join(', ');
      obj['size'] = eachLog.sizeName != null ? eachLog.sizeName : '';
      obj['process'] = eachLog.weldLogStampProcesses[0].weldProcessName;
      obj['thickness'] = eachLog.wallThickness != null ? eachLog.wallThickness : '';
      obj['schedule'] =
        eachLog.scheduleName != null ? eachLog.scheduleName.toLowerCase().replace('sch', '').trim() : '';

      bodyArr.push(obj);
    }

    return bodyArr;
  }

  public clearAllFields() {
    this.qmdbRequest = '';
    this.ndeLinkFromQMDB = '';
  }

  public submitNewLog() {
    console.log(this.qmdbRequest);
    this.showNotification(new NotificationToast('success', 'NDE successfully sent', '', 'success', 6000));
    this.ndeLinkFromQMDB = 'https://google.com';
  }
  // #endregion 'General Methods'

  //#region 'Notification'
  private showNotification(msg: NotificationToast) {
    this._srvNotify.sendNotification(msg);
  }
  //#endregion 'Notification'

  //#region 'Events'
  //emit to parent component to close form
  public cancel(): void {
    this.clearAllFields();
    this.onCancel.emit();
  }
  //#endregion
}
